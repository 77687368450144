<template>
  <b-modal
      v-if="form.product"
      v-model="visibleModal"
      title="Duplikowanie produktu"
      title-class="font-18"
      hide-footer
      @hide="hideModal"
      @esc="hideModal">
    <div class="text-center">
      <label>Produkt: <b>{{ form.product.name }}</b></label>
    </div>
    <div class="form-group">
      <ecat-multiselect
          :set-value="form.depotId"
          @change="value => changeDepotId(value)"
          :can-unselect="true"
          save-id="id"
          view-id="name"
          label="Hurtownia ECAT"
          placeholder="Wybierz hurtownie ecat"
          load-url="/depot/pagination"
          query-url="/depot/by-name"
          list-name="depots"
          param="name"
          :class="{ 'is-invalid': $v.form.depotId.$error }"
      />

      <div v-if="!$v.form.depotId.required" class="invalid-feedback">{{ $t('message.required')}}</div>
    </div>

    <div class="form-group" v-if="form.depotId">
      <ecat-multiselect
          :set-value="form.depotSectionId"
          @change="value => form.depotSectionId = value"
          :can-unselect="true"
          save-id="id"
          view-id="name"
          label="Magazyn źródłowy"
          placeholder="Wybierz magazyn"
          load-url="/depot/section/pagination"
          query-url="/depot/section/by-name"
          list-name="depotSections"
          param="name"
          :custom-params="{ depotId: form.depotId }"
          :class="{ 'is-invalid': $v.form.depotSectionId.$error }"
      />

      <div v-if="!$v.form.depotSectionId.required" class="invalid-feedback">{{ $t('message.required')}}</div>
    </div>

    <div class="form-group" v-if="!defaultSelectedVariation">
      <ecat-multiselect
          @change="value => form.variationId = value"
          label="Wariacja produktu"
          placeholder="Wybierz wariację produktu"
          load-url="/product/variation/list-with-attributes-included"
          save-id="id"
          view-id="name"
          :custom-params="{ productId: form.product ? form.product.id : '' }"
          :class="{ 'is-invalid': $v.form.variationId.$error }"
      />

      <div v-if="!$v.form.variationId.required" class="invalid-feedback">{{ $t('message.required')}}</div>
    </div>

    <div class="form-group">
      <label>Ilość na stanie</label>
      <input v-model.number="form.stock" type="number" class="form-control" />
    </div>

    <div class="text-right">
      <ecat-button :callback="() => duplicateProduct()" variant="success" :message="$t('message.save')" />
      <b-button class="ml-1" variant="danger" @click="hideModal">{{  $t('message.cancel') }}</b-button>
    </div>
  </b-modal>
</template>

<script>
import {required} from "vuelidate/lib/validators";
import {errorCatcher} from "@/helpers/error-catcher";
import axios from "axios";
import Swal from "sweetalert2";

export default {

  data() {
    return {
      visibleModal: false,
      defaultSelectedVariation: false,

      form: {
        product: null,
        variationId: "",
        depotId: "",
        depotSectionId: "",
        stock: 1
      }
    }
  },

  validations: {
    form: {
      depotId: { required },
      depotSectionId: { required },
      variationId: { required }
    }
  },

  methods: {
    changeDepotId(depotId) {
      this.form.depotId = depotId
      this.form.depotSectionId = ""
    },

    openModal(product, variationId) {
      this.form.product = product
      this.form.variationId = variationId
      this.defaultSelectedVariation = !!variationId
      this.visibleModal = true
    },

    hideModal() {
      this.visibleModal = false
      this.form.product = null
      this.form.depotSectionId = ""
      this.form.depotId = ""
      this.form.variationId = ""
      this.defaultSelectedVariation = false
      this.form.stock = 1
    },

    async duplicateProduct() {
      this.$v.form.$touch();
      if (this.$v.form.$invalid) {
        return;
      }

      if (this.form.stock <= 0) {
        Swal.fire(this.$t("message.error"), "Podana ilość na stanie jest nieprawidłowa!", "error")
        return
      }

      try {
        const json = {
          depotSectionId: this.form.depotSectionId,
          productId: this.form.product.id,
          productVariationId: this.form.variationId,
          stock: this.form.stock
        }

        await axios.post(`/depot/product/variation/duplicate`, json, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        this.hideModal()
        await Swal.fire("Sukces!", "Zduplikowano produkt", "success")
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    }
  }

}
</script>