export const oversizeTypeHelper = {
    getOversizeTypeText
};

function getOversizeTypeText(text, additionalText = "") {
    if (text === 'NONE') {
        return "Brak"
    }

    try {
        return additionalText + text.split("_")[1]
    } catch (error) {
        return ""
    }
}