import Swal from "sweetalert2";
import {translationHelper} from "@/helpers/translation-helper";

export const swalHelper = {
    yesOrNo,
    yesOrNoWithSubTitle,
    yesOrNoCustomizable
};

function yesOrNoWithSubTitle(callback, showSubTitle) {
    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: "btn btn-success",
            cancelButton: "btn btn-danger ml-2"
        },
        buttonsStyling: false
    });

    swalWithBootstrapButtons.fire({
        title: translationHelper.t('alert.title'),
        text: showSubTitle ? translationHelper.t('alert.message') : "",
        icon: "warning",
        confirmButtonText: translationHelper.t('alert.yes'),
        cancelButtonText: translationHelper.t('alert.no'),
        showCancelButton: true
    }).then(result => {
        if (result.value) {
            callback()
        }
    })
}

function yesOrNo(callback) {
    return yesOrNoWithSubTitle(callback, true)
}

function yesOrNoCustomizable(callback, icon, title, subTitle, confirmButton, cancelButton, confirmButtonStyle, cancelButtonStyle) {
    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: confirmButtonStyle,
            cancelButton: cancelButtonStyle + " ml-2"
        },
        buttonsStyling: false
    });

    swalWithBootstrapButtons.fire({
        title: title,
        text: subTitle,
        icon: icon,
        confirmButtonText: confirmButton,
        cancelButtonText: cancelButton,
        showCancelButton: true
    }).then(result => {
        if (result.value) {
            callback()
        }
    })
}