<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import axios from "axios";
import {paginationHelper} from "@/helpers/pagination-helper";
import {jsonUtil} from "@/helpers/json-util";
import Swal from "sweetalert2";
import {errorCatcher} from "@/helpers/error-catcher";
import EcatLightboxGallery from "@/components/ecat-lightbox-gallery.vue";
import {productHelper} from "@/helpers/product-helper";
import CustomCardSubtitle from "@/components/custom-card/custom-card-subtitle.vue";
import ChangeOversizeModal from "../../../../components/product/change-oversize-modal.vue";
import {oversizeTypeHelper} from "../../../../helpers/oversize-type-helper";
import {priceHelper} from "../../../../helpers/price-helper";
import EcatInput from "@/components/ecat-input.vue";

export default {

  components: {
    EcatInput,
    ChangeOversizeModal,
    CustomCardSubtitle,
    EcatLightboxGallery,
    Layout,
    PageHeader
  },

  data() {
    return {
      submitted: false,

      table: {
        rows: 0,
        totalRows: 0,
        currentPage: 1,
        perPage: 100,
        pageOptions: [5, 10, 25, 50, 100, 500, 1000],
        filter: "",
        filterOn: [],
        sortBy: "name",
        sortDesc: false,
        inputPage: "",
        elements: [],

        isBusy: false
      },

      form: {
        productVariationsIds: []
      },

      selectedProductImage: {
        visible: false,
        images: []
      },

      tableColumns: {
        visibleModal: false,

        sku: true,
        image: true,
        warehouseProduct: true,
        name: true,
        categoryName: true,
        ean: true,
        profitMargin: true,
        priceHigh: true,
        priceWholesaleHigh: true,
        expect: true,
        totalWarehouseStock: true,
        warehouse: true,
        tax: true,
        deliveryTime: true,
        shippingCost: true,
        industry: true,
        oversize: true
      },

      filtering: {
        showProductsWithEAN: false,
        bestSellingProducts: false,
        sku: "",
        ean: "",
        warehouseProduct: "",
        name: "",
        priceMin: 0,
        priceMax: 0,
        warehouseId: "",
        categoryId: "",
        industryId: "",
        attributeGroupId: "",
        attributeValue: ""
      },

      bestSellingProductsIds: [],
      shippingCosts: null,

      productInternalIdentifications: new Map(),

      orderByMap: new Map([
        ["sku", "pv.sku"],
        ["warehouseVariationId", "pv.warehouse_variation_id"],
        ["productPriceHigh", "p.product_price_high"],
        ["productPriceWholesaleHigh", "p.product_price_wholesale_high"],
        ["productTotalWarehouseStock", "p.product_total_warehouse_stock"],
        ["taxRate", "p.tax_id"]
      ])
    };
  },

  methods: {

    setToFirstPageAndRefresh() {
      this.paginationHelper.setToFirstPageAndRefresh(this, this.table, 'table')
    },

    async changeFilterShowProductsWithEan() {
      this.filtering.showProductsWithEAN = !this.filtering.showProductsWithEAN
      await this.loadVariations()
    },

    async changeBestSellingProducts() {
      this.filtering.bestSellingProducts = !this.filtering.bestSellingProducts;
      await this.loadVariations()
    },

    clearFilters() {
      this.filtering.showProductsWithEAN = false
      this.filtering.bestSellingProducts = false
      this.filtering.sku = ""
      this.filtering.ean = ""
      this.filtering.warehouseProduct = ""
      this.filtering.name = ""
      this.filtering.priceMin = 0
      this.filtering.priceMax = 0
      this.filtering.warehouseId = ""
      this.filtering.categoryId = ""
      this.filtering.industryId = ""
      this.filtering.attributeGroupId = ""
      this.filtering.attributeValue = ""
      this.$refs.table.refresh()
    },

    getItems() {
      return [
        {
          text: "ecat"
        },
        {
          text: this.$t('core.products.variations.title'),
          active: true
        }
      ]
    },

    getFields() {
      const fields = []
      fields.push({key: 'element', label: ""})

      if (this.tableColumns.sku) {
        fields.push({key: 'sku', sortable: true, label: "SKU"})
      }

      if (this.tableColumns.image) {
        fields.push({key: "image", label: this.$t('table.image')})
      }

      if (this.tableColumns.warehouseProduct) {
        fields.push({
          key: "warehouseVariationId", sortable: true, label: 'Index Hurtowni', formatter: value => {
            if (!value) {
              return "-"
            }

            return value
          }
        })
      }

      if (this.tableColumns.name) {
        fields.push({key: "productName", label: this.$t('message.name')})
      }

      if (this.tableColumns.categoryName) {
        fields.push({key: "categoryName", label: this.$t('table.category')})
      }

      if (this.tableColumns.ean) {
        fields.push({key: "ean", label: 'EAN'})
      }

      if (this.tableColumns.profitMargin) {
        fields.push({
          key: "productProfitMargin", label: this.$t('table.margin'), formatter: value => {
            if (!value) {
              return "-"
            }

            return value + "%"
          }
        })
      }

      if (this.tableColumns.priceHigh) {
        fields.push({
          key: "productPriceHigh", sortable: true, label: this.$t('table.price-high'), formatter: value => priceHelper.format(value, this.$store.getters["market/currentCurrency"])
        })
      }

      if (this.tableColumns.priceWholesaleHigh) {
        fields.push({
          key: "productPriceWholesaleHigh",
          sortable: true,
          label: this.$t('table.price-wholesale-high'),
          formatter: value => priceHelper.format(value, this.$store.getters["market/currentCurrency"])
        })
      }

      if (this.tableColumns.expect) {
        fields.push({key: "expect", label: this.$t('products.table.expect')})
      }

      if (this.tableColumns.totalWarehouseStock) {
        fields.push({key: "productTotalWarehouseStock", sortable: true, label: this.$t('table.total-warehouse-stock')})
      }

      if (this.tableColumns.warehouse) {
        fields.push({key: "warehouseName", label: this.$t('products.table.warehouse')})
      }

      if (this.tableColumns.tax) {
        fields.push({key: "taxRate", sortable: true, label: this.$t('products.table.tax-rate')})
      }

      if (this.tableColumns.deliveryTime) {
        fields.push({key: "deliveryTime", label: this.$t('products.table.delivery-time')})
      }

      if (this.tableColumns.industry) {
        fields.push({key: "industryName", label: 'Branża'})
      }

      if (this.tableColumns.oversize) {
        fields.push({key: "oversize", label: 'Gabaryt'})
      }

      if (this.tableColumns.shippingCost) {
        fields.push({key: "shippingCost", label: 'Koszt dostawy'})
      }

      fields.push({key: "action", label: this.$t('table.actions')})
      return fields
    },

    async loadVariations() {
      if (this.filtering.bestSellingProducts) {
        await this.loadBestSellingProducts()
      }

      try {
        let page = this.table.currentPage - 1;
        if (page > 0) {
          page = this.table.currentPage * this.table.perPage - this.table.perPage;
        }

        let orderBy = {}
        if (this.table.sortBy) {
          const orderByElement = this.orderByMap.get(this.table.sortBy)
          if (orderByElement) {
            orderBy = JSON.stringify({
              [orderByElement]: this.table.sortDesc ? "DESC" : "ASC"
            })
          }
        }

        const {data} = await axios.get(`/product/variation/pagination`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
          params: {
            "page": page,
            "size": this.table.perPage,
            ...this.filtering,
            "filterByProductVariationIds": this.bestSellingProductsIds.join(","),
            "orderBy": orderBy
          }
        });

        const {productVariations, count} = data
        this.table.elements = productVariations
        this.table.totalRows = count
        this.table.rows = count

        await this.loadShippingCosts()
        await this.loadProductInternalIdentifications()
        this.$refs.table.refresh()

        return this.table.elements;
      } catch (error) {
        console.log(error)
        return []
      }
    },

    async loadProductInternalIdentifications() {
      try {
        const ids = [];
        for (let productVariation of this.table.elements) {
          ids.push(productVariation.id);
        }

        if (ids.length === 0) {
          return
        }

        const json = {
          productVariationIds: ids
        }

        const { data } = await axios.post(`/product/internal/identification/by/product-variation-ids`, json, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        this.productInternalIdentifications = new Map(data.map((obj) => [obj.productVariationId, obj]));
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    productInternalIdentificationByProductVariationId(productVariationId) {
      if (!this.productInternalIdentifications) {
        return "NONE"
      }

      const productInternalIdentification = this.productInternalIdentifications.get(productVariationId)
      if (productInternalIdentification) {
        return productInternalIdentification.oversizeType
      }

      return "NONE"
    },

    async loadShippingCosts() {
      try {
        const productIds = [];
        for (let productVariation of this.table.elements) {
          productIds.push(productVariation.productId);
        }

        const json = JSON.stringify({
          "productIds": productIds
        });

        const {data} = await axios.post(`/product/target-shipping-costs`, json, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        if (data && data.data) {
          this.shippingCosts = new Map()

          data.data.forEach(item => {
            const productId = item.productId;
            const carrierData = {
              carrierId: item.carrierId,
              allegroCarrierId: item.allegroCarrierId,
              cost: item.cost,
              name: item.name
            };

            if (this.shippingCosts.has(productId)) {
              this.shippingCosts.get(productId).push(carrierData);
            } else {
              this.shippingCosts.set(productId, [carrierData]);
            }
          });
        }
      } catch (error) {
        console.log(error)
      }
    },

    getShippingCost(productId) {
      if (!this.shippingCosts || this.shippingCosts.size === 0) {
        return null;
      }

      return this.shippingCosts.get(productId) || null;
    },

    async loadBestSellingProducts() {
      try {
        const result = await axios.get(`/product/best-selling`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
        });

        const bestSellingProductsIds = []
        for (const bestSellingProduct of result.data) {
          bestSellingProductsIds.push(bestSellingProduct.productVariationId)
        }

        this.bestSellingProductsIds = bestSellingProductsIds
      } catch (error) {
        console.log(error)
      }
    },

    getTime(value) {
      const humanizeDuration = require("humanize-duration");
      return humanizeDuration(value, {language: this.$store.getters["translation/getCurrentLocaleOrFallback"]});
    },

    asArray(array) {
      try {
        return JSON.parse(array);
      } catch (error) {
        return []
      }
    },

    selectAllProductVariations() {
      const ids = []
      for (const productVariation of this.table.elements) {
        ids.push(productVariation.id)
      }

      const sameIds = this.form.productVariationsIds.length === ids.length
          && this.form.productVariationsIds.every(item => ids.includes(item))
          && this.form.productVariationsIds.every(item => ids.includes(item));

      if (sameIds) {
        this.form.productVariationsIds = []
      } else {
        this.form.productVariationsIds = ids
      }
    },

    isSameProductVariationIds() {
      const ids = []
      for (const product of this.products) {
        ids.push(product.id)
      }

      return this.form.productVariationsIds.length === ids.length
          && this.form.productVariationsIds.every(item => ids.includes(item));
    },

    highlightRow(item) {
      if (!item) {
        return ''
      }

      return this.form.productVariationsIds.includes(item.id) ? 'highlighted-product' : ''
    },

    checkSelectedProductsIsNotEmpty() {
      if (this.form.productVariationsIds.length === 0) {
        Swal.fire(this.$t('message.error'), "Najpierw zaznacz przynajmniej jedną wariację!", "error");
        return false
      }

      return true
    },

    async changeOversize() {
      const json = {
        ids: this.form.productVariationsIds,
        oversizeType: this.$refs.changeOversizeModal.getOversizeType()
      }

      try {
        await axios.post(`/product/internal/identification/update-oversize`, json, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        })

        this.form.productVariationsIds = []
        Swal.fire("Sukces!", 'Pomyślnie zmieniono status gabarytu dla wybranych wariacji', "success").then(() => {
          this.$refs.table.refresh()
        });
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    selectProductImages(images) {
      this.selectedProductImage.images = images
      this.selectedProductImage.visible = true
    },

    hideSelectedProductImage() {
      this.selectedProductImage.images = []
      this.selectedProductImage.visible = false
    },

    openOversizeModal() {
      if (!this.checkSelectedProductsIsNotEmpty()) {
        return
      }

      this.$refs.changeOversizeModal.openModal(() => this.changeOversize())
    },

  },

  computed: {
    priceHelper() {
      return priceHelper
    },
    
    productHelper() {
      return productHelper
    },

    jsonUtil() {
      return jsonUtil
    },

    paginationHelper() {
      return paginationHelper
    },

    oversizeTypeHelper() {
      return oversizeTypeHelper
    }

  }

};
</script>

<template>
  <Layout>
    <PageHeader :title="$t('core.products.variations.title')" :items="getItems()"/>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-2">
              <custom-card-subtitle title="Filtrowanie"/>

              <div class="col-lg-2 col-12">
                <ecat-input
                    id="product-name"
                    label="Nazwa produktu"
                    v-model="filtering.name"
                    type="text"
                    @should-reload="setToFirstPageAndRefresh"
                />
              </div>

              <div class="col-lg-2 col-12">
                <ecat-input
                    id="sku"
                    label="SKU"
                    v-model="filtering.sku"
                    type="text"
                    @should-reload="setToFirstPageAndRefresh"
                />
              </div>

              <div class="col-lg-2 col-12">
                <ecat-input
                    id="warehouseProduct"
                    label="Index hurtowni"
                    v-model="filtering.warehouseProduct"
                    type="number"
                    @should-reload="setToFirstPageAndRefresh"
                />
              </div>

              <div class="col-lg-2 col-12">
                <ecat-input
                    id="ean"
                    label="EAN"
                    v-model="filtering.ean"
                    type="text"
                    @should-reload="setToFirstPageAndRefresh"
                />
              </div>

              <div class="col-12">
              </div>

              <div class="col-lg-2 col-12">
                <ecat-input
                    id="price-min"
                    label="Cena minimalna"
                    v-model="filtering.priceMin"
                    type="number"
                    @should-reload="setToFirstPageAndRefresh"
                />
              </div>

              <div class="col-lg-2 col-12">
                <ecat-input
                    id="price-max"
                    label="Cena maksymalna"
                    v-model="filtering.priceMax"
                    type="number"
                    @should-reload="setToFirstPageAndRefresh"
                />
              </div>

              <div class="col-lg-2 col-12">
                <ecat-multiselect
                    @change="value => filtering.warehouseId = value"
                    @select="setToFirstPageAndRefresh"
                    save-id="id"
                    view-id="name"
                    label="Hurtownia"
                    placeholder="Wszystkie hurtownie"
                    load-url="/warehouse/list/pagination"
                    query-url="/warehouse/by-name"
                    param="name"
                    :can-unselect="true"
                    :custom-params="{withProducts: true, marketId: $store.getters['market/getMarketId']}"
                />
              </div>

              <div class="col-lg-2 col-12">
                <label for="category">Kategoria</label>
                <ecat-multiselect
                    @change="value => filtering.categoryId = value"
                    @select="setToFirstPageAndRefresh"
                    view-id="name"
                    save-id="id"
                    :can-unselect="true"
                    placeholder="Wszystkie kategorie"
                    load-url="/category/list/pagination"
                    query-url="/category/by-name"
                    param="name"
                    :custom-params="{'withProducts': true}"
                />
              </div>

              <div class="col-lg-2 col-12">
                <ecat-multiselect
                    :set-value="filtering.industryId"
                    @change="value => filtering.industryId = value"
                    @select="setToFirstPageAndRefresh"
                    :can-unselect="true"
                    save-id="id"
                    view-id="name"
                    :label="$t('message.industry')"
                    placeholder="Wybierz branże"
                    load-url="/industry/list/pagination"
                    query-url="/industry/by-name"
                    list-name="industries"
                    param="name"
                    :custom-params="{'withProducts': true}"
                />
              </div>

              <div class="col-lg-2 col-12">
              </div>

              <div class="col-lg-2 col-12">
                <ecat-multiselect
                    :set-value="filtering.attributeGroupId"
                    @change="value => filtering.attributeGroupId = value"
                    @select="setToFirstPageAndRefresh"
                    :can-unselect="true"
                    save-id="id"
                    view-id="name"
                    label="Atrybut"
                    placeholder="Wszystkie atrybuty"
                    load-url="/attribute/group/list/pagination"
                    query-url="/attribute/group/by-name"
                    param="name"
                />
              </div>

              <div class="col-lg-2 col-12">
                <label>Wartość atrybutu</label>
                <input type="text" v-model="filtering.attributeValue" class="form-control"
                       @keyup.enter="setToFirstPageAndRefresh" @input="setToFirstPageAndRefresh"/>
              </div>
            </div>

            <div class="row mb-2">
              <custom-card-subtitle title="Akcje"/>

              <div class="col-xl-12">
                <b-button variant="primary" @click="tableColumns.visibleModal = true" class="mr-2">
                  Dostosuj kolumny
                </b-button>
                <b-button variant="primary" @click="changeFilterShowProductsWithEan" class="mr-2">
                  {{ filtering.showProductsWithEAN ? 'Pokaż wszystkie wariacje' : 'Pokaż wariacje posiadające EAN' }}
                </b-button>

                <b-button variant="primary" @click="changeBestSellingProducts" class="mr-2">
                  Pokaż wariację najlepiej sprzedające się w ciągu 30 dni:
                  {{ filtering.bestSellingProducts ? 'Tak' : 'Nie' }}
                </b-button>
              </div>
            </div>

            <div class="row mb-2">
              <custom-card-subtitle title="Akcje masowe (wymagają zaznaczenia wierszy tabeli)"/>
              <div class="col-xl-12">
                <b-button variant="danger" @click="openOversizeModal">Zmień status gabarytu dla wybranych wariacji</b-button>
              </div>
            </div>

            <div class="row">
              <div class="col-lg-12 row mt-2 mb-4 pl-4">
                <b-button variant="primary" class="w-xxl mr-2" @click="clearFilters">Wyczyść filtry</b-button>
                <b-button variant="outline-primary" class="w-xxl mr-2" @click="setToFirstPageAndRefresh">Szukaj</b-button>
              </div>
            </div>

            <ecat-lightbox-gallery :visible="selectedProductImage.visible" :images="selectedProductImage.images"
                                   @hide="hideSelectedProductImage"/>

            <div class="py-2">
              <div class="row mt-4">
                <div class="col-sm-12 col-md-6">
                  <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-flex align-items-center">
                      {{ $t('table.show') }}&nbsp;
                      <b-form-select v-model="table.perPage" size="sm"
                                     :options="table.pageOptions"/>&nbsp;{{ $t('table.entries') }}
                    </label>
                  </div>
                </div>

                <div class="col-sm-12 col-md-6">
                  <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                    <label class="d-inline-flex align-items-center">
                      {{ $t('table.search') }}
                      <b-form-input
                          v-model="table.filter"
                          type="search"
                          class="form-control form-control-sm ml-2"
                          @keyup.enter="setToFirstPageAndRefresh" @input="setToFirstPageAndRefresh"
                      />
                    </label>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <div class="float-right">
                    <label class="d-inline-flex align-items-center mx-2">
                      <b-form-input v-model="table.inputPage" class="form-control form-control-sm ml-2"/>
                    </label>
                    <b-button variant="primary" @click="paginationHelper.setCurrentPage(table, table.inputPage)">Idź do
                      strony
                    </b-button>
                  </div>
                </div>
              </div>

              <div class="row py-3">
                <div class="col">
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <b-pagination v-model="table.currentPage" :total-rows="table.rows"
                                    :per-page="table.perPage"></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="table-responsive">
                <b-table
                    ref="table"

                    :items="loadVariations"
                    :fields="getFields()"
                    responsive="sm"
                    :per-page="table.perPage"
                    :current-page="table.currentPage"
                    :sort-by.sync="table.sortBy"
                    :sort-desc.sync="table.sortDesc"


                    :busy.sync="table.isBusy"
                    :tbody-tr-class="highlightRow"
                    :empty-text="$t('message.no-elements')"
                    :empty-filtered-text="$t('message.no-records')" :show-empty="true">
                  <div slot="table-busy" class="text-center">
                    <h5>{{ $t('message.loading') }}</h5>
                    <span aria-hidden="true" class="m-2 spinner-border text-primary"></span>
                  </div>

                  <template #head(element)>
                    <b-form-checkbox :value="isSameProductVariationIds"
                                     @change="selectAllProductVariations"></b-form-checkbox>
                  </template>

                  <template v-slot:cell(element)="{ item }">
                    <div class="custom-control custom-checkbox">
                      <input :value="item.id" v-model="form.productVariationsIds" type="checkbox"
                             class="custom-control-input" :id="`check-${item.id}`"
                             :class="form.productVariationsIds.includes(item.id) ? 'custom-control-label-selected' : ''"/>
                      <label class="custom-control-label" :for="`check-${item.id}`">&nbsp;</label>
                    </div>
                  </template>

                  <template v-slot:cell(image)="{ item }">
                    <div :set="images = asArray(item.productImages)">
                      <template v-if="images.length > 0">
                        <img :src="images[0]" alt="Product Image" class="rounded avatar-lg"
                             @click="selectProductImages(asArray(item.productImages))">
                      </template>
                      <template v-else>
                        <span>-</span>
                      </template>
                    </div>
                  </template>

                  <template v-slot:cell(expect)="{ item }">
                      <span class="badge font-size-11"
                            :class="productHelper.getAllegroSendStatus(item) ? 'badge-soft-success' : 'badge-soft-danger'">
                        {{ $t(productHelper.getAllegroSendStatus(item) ? 'message.yes' : 'message.no') }}
                      </span>
                  </template>

                  <template v-slot:cell(oversize)="{ item }">
                      <span class="badge font-size-11"
                            :class="productInternalIdentificationByProductVariationId(item.id) === 'NONE' ? 'badge-soft-danger' : 'badge-soft-success'">
                        {{ oversizeTypeHelper.getOversizeTypeText(productInternalIdentificationByProductVariationId(item.id), 'Typ ') }}
                      </span>
                  </template>

                  <template v-slot:cell(shippingCost)="{ item }">
                    <div :set="shippingCostForProduct = getShippingCost(item.productId)" class="custom-row-width">
                      <template v-if="shippingCostForProduct">
                        <p v-for="(shippingCostItem, index) in shippingCostForProduct" :key="index">
                          {{ shippingCostItem.name }}: {{ priceHelper.format(shippingCostItem.cost, $store.getters['market/currentCurrency']) }}</p>
                      </template>
                      <template v-else>
                        -
                      </template>
                    </div>
                  </template>

                  <template v-slot:cell(taxRate)="{ item }">
                    <span>{{ item.taxRate }}%</span>
                  </template>

                  <template v-slot:cell(deliveryTime)="{ item }">
                    <span> {{
                        item.deliveryTime === 0 ? $t('products.no-delivery-time') : getTime(item.deliveryTime)
                      }} </span>
                  </template>

                  <template v-slot:cell(ean)="{ item }">
                    <div :set="ean = asArray(item.ean)">
                      <template v-if="ean.length > 0">
                        <span>{{ ean[0] }}</span>
                        <span v-if="ean.length > 1" class="badge badge-soft-success font-size-11">+{{ ean.length - 1 }} {{
                            $t('message.others')
                          }}</span>
                      </template>
                      <template v-else>
                        <span>-</span>
                      </template>
                    </div>
                  </template>

                  <template v-slot:cell(action)="{ item }">
                    <a :href="`/dashboard/admin/product/show/${item.productId}`"
                       class="clickable-element mr-3 text-primary"><i class="mdi mdi-information font-size-18"></i></a>
                    <!--                      <a id="details" @click="details(item)" class="clickable-element mr-3 text-primary"><i class="mdi mdi-card-account-details font-size-18"></i></a>-->
                    <!--                      <b-tooltip target="details" placement="left">{{ $t('products.details.title')}}</b-tooltip>-->
                  </template>
                </b-table>
              </div>

              <div class="row">
                <div class="col">
                  <div class="float-left">
                    <p>{{
                        $t('table.entries-footer', {
                          'amount': paginationHelper.getElementsAmount(table),
                          'elements': paginationHelper.getElementsCount(table),
                          'all': table.totalRows
                        })
                      }}</p>
                  </div>
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <b-pagination v-model="table.currentPage" :total-rows="table.rows" :per-page="table.perPage"/>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
        id="modal-1"
        v-model="tableColumns.visibleModal"
        title="Wybierz kolumny do pokazania/ukrycia"
        title-class="font-18"
        hide-footer
        @hide="tableColumns.visibleModal = false"
        @esc="tableColumns.visibleModal = false">
      <form>
        <div class="form-group">
          <label class="mx-2"><input v-model="tableColumns.sku" type="checkbox"/> SKU</label>
          <label class="mx-2"><input v-model="tableColumns.image" type="checkbox"/> Zdjęcie</label>
          <label class="mx-2"><input v-model="tableColumns.warehouseProduct" type="checkbox"/> Index hurtowni</label>
          <label class="mx-2"><input v-model="tableColumns.name" type="checkbox"/> Nazwa produktu</label>
          <label class="mx-2"><input v-model="tableColumns.categoryName" type="checkbox"/> Nazwa kategorii</label>
          <label class="mx-2"><input v-model="tableColumns.ean" type="checkbox"/> EAN</label>
          <label class="mx-2"><input v-model="tableColumns.profitMargin" type="checkbox"/> Narzut</label>
          <label class="mx-2"><input v-model="tableColumns.priceHigh" type="checkbox"/> Cena sklepu brutto</label>
          <label class="mx-2"><input v-model="tableColumns.priceWholesaleHigh" type="checkbox"/> Cena hurtowni
            netto</label>
          <label class="mx-2"><input v-model="tableColumns.expect" type="checkbox"/> Dozwolony do sprzedaży na
            Allegro</label>
          <label class="mx-2"><input v-model="tableColumns.totalWarehouseStock" type="checkbox"/> Ilość sztuk</label>
          <label class="mx-2"><input v-model="tableColumns.warehouse" type="checkbox"/> Hurtownia</label>
          <label class="mx-2"><input v-model="tableColumns.tax" type="checkbox"/> Stawka podatku</label>
          <label class="mx-2"><input v-model="tableColumns.deliveryTime" type="checkbox"/> Czas dostawy</label>
          <label class="mx-2"><input v-model="tableColumns.shippingCost" type="checkbox"/> Koszt dostawy</label>
          <label class="mx-2"><input v-model="tableColumns.industry" type="checkbox"/> Branża</label>
          <label class="mx-2"><input v-model="tableColumns.oversize" type="checkbox"/> Gabaryt</label>
        </div>
      </form>
    </b-modal>

    <change-oversize-modal ref="changeOversizeModal" />

  </Layout>
</template>