<script>
import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import Swal from "sweetalert2";
import axios from "axios";
import {jsonUtil} from "@/helpers/json-util";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {errorCatcher} from "@/helpers/error-catcher";
import {swalHelper} from "@/helpers/swal-helper";
import {paginationHelper} from "@/helpers/pagination-helper";
import {dateUtil} from "@/helpers/date-util";
import {required} from "vuelidate/lib/validators";
import EcatLightboxGallery from "@/components/ecat-lightbox-gallery.vue";
import EcatCkeditor from "../../../../components/ecat-ckeditor.vue";
import {oversizeTypeHelper} from "../../../../helpers/oversize-type-helper";
import {priceHelper} from "../../../../helpers/price-helper";
import variations from "../../core/products/variations.vue";
import depotDuplicateProductModal from "@/components/depot/depot-duplicate-product-modal.vue";

export default {
  computed: {
    variations() {
      return variations
    },
    priceHelper() {
      return priceHelper
    },

    oversizeTypeHelper() {
      return oversizeTypeHelper
    },

    paginationHelper() {
      return paginationHelper
    },

    jsonUtil() {
      return jsonUtil
    },

    dateUtil() {
      return dateUtil
    },

    productAttribute() {
      return (variationId) => {
        return this.getAttribute(variationId);
      };
    }
  },

  components: {
    depotDuplicateProductModal,
    EcatCkeditor,
    EcatLightboxGallery,
    Layout,
    PageHeader
  },

  data() {
    return {
      submitted: false,
      // uniqueDepotIds: {},
      // uniqueDepotStocks: [],

      product: null,
      productVariations: null,
      attributes: null,
      category: null,
      warehouse: null,
      depot: null,
      market: null,
      tax: null,
      deliveryUnits: null,
      deliveryUnitsMap: null,
      depotSectionStocks: null,
      shippingCosts: null,
      productInternalIdentifications: new Map(),
      lastModifierUser: null,
      productVariationsStockReservationAmount: new Map(),

      editor: ClassicEditor,

      selectedProductImage: {
        visible: false,
        currentIndex: 0,
        images: []
      },

      modals: {

        editElementInTable: {

          item: null,
          itemType: '',
          title: "",
          visibleModal: false,
          element: "",
          elementName: "",
          type: ""

        },

        addStock: {

          visible: false,

          form: {

            productVariationId: "",
            depotSectionId: "",
            stockQuantity: 0

          }

        },

        moveStock: {

          visible: false,

          form: {

            productVariationId: "",
            depotSectionIdFrom: "",
            depotSectionIdTo: "",
            stockQuantity: 0

          }

        },

        changeStock: {

          visible: false,

          form: {

            productVariationId: "",
            depotSectionId: "",
            stockQuantity: 0

          }

        },

        changeOversize: {

          visible: false,

          form: {

            productVariationId: "",
            oversizeType: "NONE",
          }

        }

      },

      logsTable: {
        rows: 0,
        totalRows: 0,
        currentPage: 1,
        perPage: 100,
        pageOptions: [5, 10, 25, 50, 100],
        filter: null,
        filterOn: [],
        sortBy: "name",
        sortDesc: false,
        isBusy: false,

        items: []
      },

      packageTypes: [ "TYPE_A", "TYPE_B", "TYPE_C", "NONE"]

    };
  },

  validations: {

    modals: {

      addStock: {
        form: {
          depotSectionId: {required},
          stockQuantity: {required}
        }
      },

      moveStock: {
        form: {
          depotSectionIdFrom: {required},
          depotSectionIdTo: {required},
          stockQuantity: {required}
        }
      },

      changeStock: {
        form: {
          stockQuantity: {required}
        }
      },

      changeOversize: {
        form: {
          oversizeType: { required }
        }
      }

    }

  },

  methods: {

    getItems() {
      return [
        {
          text: "ecat"
        },
        {
          text: this.$t('admin.product.show.title', {'product_name': this.product ? this.product.name : ''}),
          active: true
        }
      ]
    },

    async loadDeliveryUnits() {
      try {
        const result = await axios.get(`/delivery-unit`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
        });

        this.deliveryUnits = result.data.deliveryUnits
        this.deliveryUnitsMap = new Map(this.deliveryUnits.map((obj) => [obj.deliveryUnit, obj]));
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    getDeliveryUnit(unit) {
      if (!this.deliveryUnitsMap) {
        return null
      }

      return this.deliveryUnitsMap.get(unit) || null
    },

    async loadProduct() {
      try {
        const id = this.$route.params.id;
        if (!id) {
          await Swal.fire("", "Nie znaleziono takiego produktu!", "error");
          return;
        }

        const { data } = await axios.get(`/product/fetch`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
          params: {
            "productId": id
          }
        })

        this.product = data
        await this.loadLastModifierUser()
        await this.loadCategory()
        await this.loadWarehouse()
        await this.loadTax()
        await this.loadVariations()
        await this.loadShippingCosts()
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadLastModifierUser() {
      if (!this.product.lastModifierUserId) {
        return
      }

      try {
        const {data} = await axios.get(`/user/${this.product.lastModifierUserId}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {}
        })

        this.lastModifierUser = data
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadShippingCosts() {
      try {
        const json = JSON.stringify({
          "productIds": [this.product.id]
        });

        const { data } = await axios.post(`/product/target-shipping-costs`, json, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        if (data && data.data) {
          this.shippingCosts = new Map()

          data.data.forEach(item => {
            const productId = item.productId;
            const carrierData = {
              carrierId: item.carrierId,
              allegroCarrierId: item.allegroCarrierId,
              cost: item.cost,
              name: item.name
            };

            if (this.shippingCosts.has(productId)) {
              this.shippingCosts.get(productId).push(carrierData);
            } else {
              this.shippingCosts.set(productId, [carrierData]);
            }
          });
        }
      } catch (error) {
        console.log(error)
      }
    },

    getShippingCost(productId) {
      if (!this.shippingCosts || this.shippingCosts.size === 0) {
        return null;
      }

      return this.shippingCosts.get(productId) || null;
    },

    async loadVariations() {
      try {
        let json = {productId: this.product.id}

        const { data } = await axios.post(`/product/variation`, json, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        this.productVariations = data
        await this.loadProductVariationsStockReservationAmount()
        await this.loadAttributes()
        await this.loadDepotSectionStocks()
        await this.loadProductInternalIdentifications()
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadProductVariationsStockReservationAmount() {
      if (!this.productVariations || this.productVariations.length === 0) {
        return
      }

      try {
        const ids = this.productVariations.map(productVariation => productVariation.id);
        const json = {
          productVariationIds: ids
        }

        const { data } = await axios.post(`/product/variation/stock-reservation/amount`, json, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        this.productVariationsStockReservationAmount = new Map(data.map((obj) => [obj.productVariationId, obj]));
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    getSumStockReservation() {
      let quantity = 0
      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of this.productVariationsStockReservationAmount) {
        quantity += value.reservedAmount
      }

      return quantity
    },

    getProductVariationStockReservation(productVariationId) {
      if (!this.productVariationsStockReservationAmount) {
        return 0
      }

      return this.productVariationsStockReservationAmount.get(productVariationId)?.reservedAmount || 0
    },

    async loadProductInternalIdentifications() {
      if (!this.productVariations || this.productVariations.length === 0) {
        return
      }

      try {
        const ids = [];
        for (let productVariation of this.productVariations) {
          ids.push(productVariation.id);
        }

        if (ids.length === 0) {
          return
        }

        const json = {
          productVariationIds: ids
        }

        const { data } = await axios.post(`/product/internal/identification/by/product-variation-ids`, json, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        this.productInternalIdentifications = new Map(data.map((obj) => [obj.productVariationId, obj]));
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    productInternalIdentificationByProductVariationId(productVariationId) {
      if (!this.productInternalIdentifications) {
        return "NONE"
      }

      const productInternalIdentification = this.productInternalIdentifications.get(productVariationId)
      if (productInternalIdentification) {
        return productInternalIdentification.oversizeType
      }

      return "NONE"
    },

    async loadDepotSectionStocks() {
      try {
        const ids = this.productVariations.map(productVariation => productVariation.id);
        const json = {
          productVariationIds: ids
        }

        const {data} = await axios.post(`/depot/section/stock/product-variation-stock-summary`, json, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        this.depotSectionStocks = data
        // this.depotSectionStocks.forEach(element => {
        //   if (!this.uniqueDepotIds[element.depotId]) {
        //     this.uniqueDepotIds[element.depotId] = true;
        //     this.uniqueDepotStocks.push(element);
        //   }
        // });
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    getDepotSectionStockDepots(productVariationId) {
      const uniqueDepotIds = new Map();
      const uniqueStocks = [];

      for (const element of this.depotSectionStocks) {
        if (element.productVariationId === productVariationId) {
          if (!uniqueDepotIds.has(element.depotId)) {
            uniqueDepotIds.set(element.depotId, true);
            uniqueStocks.push(element);
          }
        }
      }

      return uniqueStocks;
    },

    getSumDepotStocks(productVariationId) {
      if (!this.depotSectionStocks) {
        return 0
      }

      let quantity = 0
      for (const depotStock of this.depotSectionStocks) {
        if (depotStock.productVariationId === productVariationId) {
          quantity += depotStock.quantity
        }
      }

      return quantity
    },

    getSumDepotSectionStocks(depotId, productVariationId) {
      if (!this.depotSectionStocks) {
        return 0
      }

      let quantity = 0
      for (const depotStock of this.depotSectionStocks) {
        if (depotStock.depotId === depotId && depotStock.productVariationId === productVariationId) {
          quantity += depotStock.quantity
        }
      }

      return quantity
    },

    async loadAttributes() {
      const ids = []
      for (const productVariation of this.productVariations) {
        ids.push(productVariation.id)
      }

      if (ids.length === 0) {
        return
      }

      const json = {
        productVariationIds: ids
      }

      const { data } = await axios.post(`/product/variation/attribute`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      });

      this.attributes = new Map(data.map((obj) => [obj.productVariationId, obj]));
    },

    getAttribute(productVariationId) {
      if (!this.attributes) {
        return null
      }

      return this.attributes.get(productVariationId) || null
    },

    async loadCategory() {
      try {
        const { data } = await axios.get(`/category/${this.product.categoryId}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {}
        })

        this.category = data
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadWarehouse() {
      try {
        const { data } = await axios.get(`/warehouse/${this.product.warehouseId}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {}
        })

        this.warehouse = data
        await this.loadMarket()
        await this.loadDepot()
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadMarket() {
      try {
        const { data } = await axios.get(`/market/${this.warehouse.marketId}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {}
        })

        this.market = data
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadDepot() {
      try {
        const { data } = await axios.get(`/depot/by-warehouse/${this.warehouse.id}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {}
        })

        this.depot = data
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadTax() {
      try {
        const result = await axios.get(`/tax/${this.product.taxId}`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {}
        })

        this.tax = result.data
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    getProductStatus() {
      if (this.product.checked) {
        return 'Zatwierdzony'
      }

      return 'Nie zatwierdzony'
      // if (this.product.status === 0) {
      //   return 'Nie zatwierdzony'
      // } else if (this.product.status === 1) {
      //   return 'Zatwierdzony'
      // } else if (this.product.status === 2) {
      //   return 'Usunięty'
      // }
    },

    saveProduct(callback, edited) {
      this.submitted = true;

      const json = JSON.stringify((edited) ? this.modals.editElementInTable.item : this.product);

      let url = '/product/edit'
      if (this.modals.editElementInTable.itemType === 'PRODUCT_VARIATION') {
        url = '/product/variation/edit'
      }

      axios.post(`${url}`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      }).then(() => {
        if (callback) {
          callback()
        }

        this.$bvToast.toast('Zaktualizowano produkt', {
          title: this.$t('message.success'),
          variant: 'success',
          solid: true
        });

        this.loadProduct()
      }).catch((error) => {
        errorCatcher.catchErrors(error)
        this.submitted = false;
      })
    },

    selectProductImages(images, currentIndex) {
      this.selectedProductImage.currentIndex = currentIndex
      this.selectedProductImage.images = images
      this.selectedProductImage.visible = true
    },

    hideSelectedProductImage() {
      this.selectedProductImage.currentIndex = 0
      this.selectedProductImage.images = []
      this.selectedProductImage.visible = false
    },

    ecatGrossSellingPrice() {
      if (!this.tax) {
        return 'Tax not found'
      }

      if (!this.productVariations) {
        return 'Variations not found'
      }

      const variation = this.productVariations[0]

      let value = 0
      if (this.product.profitMargin !== 0 && this.product.profitMarginStatus === 1) {
        value = variation.wholesalePrice * (1 + this.tax.rate / 100) * (1 + this.product.profitMargin / 100)
      } else if (this.product.profitMarginStatus === 2 && variation.profitMargin !== 0) {
        value = variation.wholesalePrice * (1 + this.tax.rate / 100) * (1 + variation.profitMargin / 100)
      } else if (this.warehouse.profitMargin !== 0) {
        value = variation.wholesalePrice * (1 + this.tax.rate / 100) * (1 + this.warehouse.profitMargin / 100)
      } else {
        value = variation.wholesalePrice * (1 + this.tax.rate / 100) * (1 + this.category.profitMargin / 100)
      }

      return priceHelper.format(value, this.market.currency)
    },

    productPriceRange(bruttoOnly) {
      if (!this.productVariations) {
        return 'Variations not found'
      }

      if (this.productVariations.length > 1 && this.product.priceWholesaleHigh > this.product.priceWholesaleLow) {
        if (bruttoOnly) {
          return priceHelper.format(this.product.minMargin, this.market.currency) + " - " + priceHelper.format(this.product.maxMargin, this.market.currency)
        }

        return priceHelper.format(this.product.priceWholesaleLow, this.market.currency) + " - " + priceHelper.format(this.product.priceWholesaleHigh, this.market.currency) + "(" + priceHelper.format(this.product.minMargin, this.market.currency) + " - " + priceHelper.format(this.product.maxMargin, this.market.currency) + ")"
      } else if (this.productVariations.length > 1 && this.product.maxMargin > this.product.minMargin) {
        return priceHelper.format(this.product.priceWholesaleLow, this.market.currency) + " - (" + priceHelper.format(this.product.minMargin, this.market.currency) + " - " + priceHelper.format(this.product.maxMargin, this.market.currency) + ")"
      }

      if (bruttoOnly) {
        return priceHelper.format(this.product.minMargin, this.market.currency)
      }

      return priceHelper.format(this.product.priceWholesaleLow, this.market.currency) + " (" + priceHelper.format(this.product.minMargin, this.market.currency) + ")"
    },

    hideEditElementInTableModal() {
      this.modals.editElementInTable.visibleModal = false
      this.modals.editElementInTable.item = null
      this.modals.editElementInTable.itemType = null
      this.modals.editElementInTable.title = ""
      this.modals.editElementInTable.element = ""
      this.modals.editElementInTable.elementName = ""
      this.modals.editElementInTable.type = ""
    },

    openEditElementInTableModal(item, element, elementName, type, title, itemType = 'PRODUCT') {
      this.modals.editElementInTable.item = Object.assign({}, item)
      this.modals.editElementInTable.itemType = itemType
      this.modals.editElementInTable.element = element
      this.modals.editElementInTable.title = title
      this.modals.editElementInTable.elementName = elementName
      this.modals.editElementInTable.type = type
      this.modals.editElementInTable.visibleModal = true
    },

    blockOrUnBlockProduct(blocked) {
      swalHelper.yesOrNoWithSubTitle(() => {
        const callback = () => {
          this.$bvToast.toast((blocked) ? 'Produkt został oznaczony jako zablokowany' : 'Produkt został oznaczony jako odblokowany', {
            title: this.$t('message.success'),
            variant: 'success',
            solid: true
          });
        }

        this.modals.editElementInTable.item = Object.assign({}, this.product)
        this.modals.editElementInTable.item.status = (blocked) ? 'BLOCKED' : 'NORMAL'

        this.saveProduct(callback, true)
      }, "");
    },

    acceptProduct() {
      swalHelper.yesOrNoWithSubTitle(() => {
        const callback = () => {
          this.$bvToast.toast('Produkt został zatwierdzony', {
            title: this.$t('message.success'),
            variant: 'success',
            solid: true
          });
        }

        this.modals.editElementInTable.item = Object.assign({}, this.product)
        this.modals.editElementInTable.item.checked = true

        this.saveProduct(callback, true)
      }, "");
    },

    getTime(value) {
      if (value === 0) {
        return this.$t('products.no-delivery-time')
      }

      const humanizeDuration = require("humanize-duration");
      return humanizeDuration(value, { language: this.$store.getters["translation/getCurrentLocaleOrFallback"] });
    },

    getLogsFields() {
      return [
        {key: "userId", slot: true, label: "Użytkownik" },
        {key: 'description', label: 'Opis'},
        {key: "newValue", label: 'Nowa wartość' },
        {key: "createdAt", label: this.$t('allegro.table.created-at'), formatter: dateUtil.asDateTime },
      ]
    },

    async loadProductLogs() {
      try {
        let page = this.logsTable.currentPage - 1;
        if (page > 0) {
          page = this.logsTable.currentPage * this.logsTable.perPage - this.logsTable.perPage;
        }

        const { data } = await axios.get(`/product/log/list/pagination`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          },
          data: {},
          params: {
            "page": page,
            "size": this.logsTable.perPage,
            "productId": this.product.id
          }
        });

        this.logsTable.items = data.productLogs
        this.logsTable.rows = data.count
        this.logsTable.totalRows = data.count
      } catch (error) {
        errorCatcher.catchErrors(error)
      }

      return this.logsTable.items;
    },

    openAddStockModal(productVariationId) {
      this.modals.addStock.visible = true
      this.modals.addStock.form.productVariationId = productVariationId
    },

    hideAddStockModal() {
      this.modals.addStock.visible = false
      this.modals.addStock.form.productVariationId = ""
      this.modals.addStock.form.depotSectionId = ""
      this.modals.addStock.form.stockQuantity = 0
    },

    addStock() {
      this.submitted = true
      this.$v.modals.addStock.form.$touch();
      if (this.$v.modals.addStock.form.$invalid) {
        return;
      }

      const json = JSON.stringify(this.modals.addStock.form)
      axios.put(`/depot/section/stock`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      }).then(() => {
        Swal.fire("Sukces!", "Dodano wariację na magazyn", "success").then(() => {
          setTimeout(() => this.$router.go(this.$router.currentRoute), 350)
        });
      }).catch((error) => {
        errorCatcher.catchErrors(error)
      })
    },

    openMoveStockModal(productVariationId) {
      this.modals.moveStock.visible = true
      this.modals.moveStock.form.productVariationId = productVariationId
    },

    hideMoveStockModal() {
      this.modals.moveStock.visible = false
      this.modals.moveStock.form.productVariationId = ""
      this.modals.moveStock.form.depotSectionIdFrom = ""
      this.modals.moveStock.form.depotSectionIdTo = ""
      this.modals.moveStock.form.stockQuantity = 0
    },

    moveStock() {
      this.submitted = true
      this.$v.modals.moveStock.form.$touch();
      if (this.$v.modals.moveStock.form.$invalid) {
        return;
      }

      const json = JSON.stringify(this.modals.moveStock.form)
      axios.post(`/depot/section/stock/move`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      }).then(() => {
        Swal.fire("Sukces!", "Zaktualizowano stan wariacji w magazynie", "success").then(() => {
          setTimeout(() => this.$router.go(this.$router.currentRoute), 350)
        });
      }).catch((error) => {
        errorCatcher.catchErrors(error)
      })
    },

    openChangeStockModal(depotSectionId, productVariationId) {
      this.modals.changeStock.visible = true
      this.modals.changeStock.form.depotSectionId = depotSectionId
      this.modals.changeStock.form.productVariationId = productVariationId
    },

    hideChangeStockModal() {
      this.modals.changeStock.visible = false
      this.modals.changeStock.form.productVariationId = ""
      this.modals.changeStock.form.depotSectionId = ""
      this.modals.changeStock.form.stockQuantity = 0
    },

    changeStock() {
      this.submitted = true
      this.$v.modals.changeStock.form.$touch();
      if (this.$v.modals.changeStock.form.$invalid) {
        return;
      }

      const json = JSON.stringify(this.modals.changeStock.form)
      axios.post(`/depot/section/stock/change`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      }).then(() => {
        Swal.fire("Sukces!", "Zmieniono stan magazynowy", "success").then(() => {
          setTimeout(() => this.$router.go(this.$router.currentRoute), 350)
        });
      }).catch((error) => {
        errorCatcher.catchErrors(error)
      })
    },

    openChangeOversizeModal(productVariationId) {
      this.modals.changeOversize.visible = true
      this.modals.changeOversize.form.productVariationId = productVariationId
      this.modals.changeOversize.form.oversizeType = this.productInternalIdentificationByProductVariationId(productVariationId)
    },

    hideChangeOversizeModal() {
      this.$v.modals.changeOversize.form.$reset()
      this.modals.changeOversize.visible = false
      this.modals.changeOversize.form.productVariationId = ""
      this.modals.changeOversize.form.oversizeType = ""
    },

    setDimensions() {
      this.$v.modals.changeOversize.form.$touch();
      if (this.$v.modals.changeOversize.form.$invalid) {
        return;
      }

      const json = {
        id: this.modals.changeOversize.form.productVariationId,
        oversizeType: this.modals.changeOversize.form.oversizeType
      }

      axios.post(`/product/internal/identification/update-one-oversize`, json, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        },
      }).then(() => {
        this.hideChangeOversizeModal()
        Swal.fire("Sukces!", 'Pomyślnie zmieniono status gabarytu dla wariacji', "success").then(() => {
          this.loadProductInternalIdentifications()
        });
      }).catch((error) => {
        errorCatcher.catchErrors(error)
      })
    },

  },

  async created() {
    try {
      await this.loadProduct()
      await this.loadDeliveryUnits()
    } catch (error) {
      console.log(error)
    }
  }

};
</script>

<template>
  <Layout>
    <PageHeader :title="$t('admin.product.show.title', { 'product_name': product ? product.name : '' })" :items="getItems()" />

    <ecat-lightbox-gallery :current-index="selectedProductImage.currentIndex" :visible="selectedProductImage.visible" :images="selectedProductImage.images" @hide="hideSelectedProductImage" />

    <div class="row">
      <div class="col-12">
        <div class="mb-2">
          <router-link to="/dashboard/admin/products" class="btn btn-primary w-sm mb-2">
            {{ $t('page.back') }}
          </router-link>
        </div>
      </div>

      <template v-if="product && market">
      <div class="col-12 col-lg-6">
        <div class="card">
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-hover table-bordered">
                <tbody><tr>
                  <th>Cecha</th>
                  <th>Wartość</th>
                  <th>Akcje</th>
                </tr>

                <tr>
                  <td>SKU</td>
                  <td>{{  product.sku ? product.sku : "-" }}</td>
                  <td></td>
                </tr>

                <tr>
                  <td>Nazwa kategorii</td>
                  <td>{{ category ? category.name : "" }}</td>
                  <td>
                    <button class="btn btn-sm btn-success" @click="openEditElementInTableModal(product, 'categoryId', 'Kategoria', 'CATEGORY', 'Zmiana kategorii')">
                      <i class="mdi mdi-pencil"></i>
                    </button>
                  </td>
                </tr>

                <tr>
                  <td>Ścieżka kategorii</td>
                  <td>{{ (category && category.path) ? category.path : "-" }}</td>
                  <td></td>
                </tr>

                <tr>
                  <td>Zakres cenowy, ZAKUP (SPRZEDAŻ)</td>
                  <td>{{ productPriceRange(false) }}</td>
                  <td>
                    <template v-if="product.variationsCount === 1">
                      <!-- TODO -->
                    </template>
                  </td>
                </tr>

                <tr>
                  <td>Łączny stan</td>
                  <td>
                    Zarezerwowanych sztuk: {{ getSumStockReservation() }}
                    <br /> <br />
                    Stan z hurtowni: {{  product.totalWarehouseStock + product.totalDepotStock }}
                    <br /> <br />
                      <p>Łącznie: (<span id="available_in" class="text-underline">{{ product.totalWarehouseStock + product.totalDepotStock }}</span>
                        - <span id="stock_reservation" class="text-underline">{{ getSumStockReservation() }}</span>)
                        = <span id="sum" class="text-underline">{{ product.totalWarehouseStock + product.totalDepotStock - getSumStockReservation() }}</span>
                      </p>
                      <b-tooltip target="available_in" placement="right">Stan z hurtowni</b-tooltip>
                      <b-tooltip target="stock_reservation" placement="right">Zarezerwowanych sztuk</b-tooltip>
                      <b-tooltip target="sum" placement="right">Suma sztuk</b-tooltip>
                  </td>
                  <td></td>
                </tr>

                <tr>
                  <td>Narzut</td>
                  <td>{{ product.profitMargin }}%</td>
                  <td>
                    <button class="btn btn-sm btn-success" @click="openEditElementInTableModal(product, 'profitMargin', 'Narzut', 'DOUBLE', 'Zmiana narzutu')">
                      <i class="mdi mdi-pencil"></i>
                    </button>
                  </td>
                </tr>

                <tr>
                  <td>Narzut hurtowni</td>
                  <td>{{ warehouse ? warehouse.profitMargin : '0' }}%</td>
                  <td></td>
                </tr>

                <tr>
                  <td>Cena sprzedaży brutto ECAT</td>
                  <td>{{ ecatGrossSellingPrice() }}</td>
                  <td></td>
                </tr>

                <tr>
                  <td>Stawka VAT</td>
                  <td v-if="tax">{{  tax.rate }}%</td>
                  <td>
                    <button class="btn btn-sm btn-success" @click="openEditElementInTableModal(product, 'taxId', 'Stawka VAT', 'TAX', 'Zmiana stawki VAT')">
                      <i class="mdi mdi-pencil"></i>
                    </button>
                  </td>
                </tr>

                <tr>
                  <td>Indeks hurtowni</td>
                  <td>{{  product.warehouseProduct }}</td>
                  <td></td>
                </tr>

                <tr>
                  <td>Producent</td>
                  <td>{{  product.manufacturer ? product.manufacturer : '-'  }}</td>
                  <td></td>
                </tr>

                <tr>
                  <td>Nazwa hurtowni</td>
                  <td v-if="warehouse"><a :href="`/dashboard/admin/warehouse/show/${warehouse.id}`" target="_blank">{{ warehouse ? warehouse.name : "" }}</a></td> <!-- TODO show warehouse details -->
                  <td></td>
                </tr>

                <tr>
                  <td>Ostatni zmieniający</td>
                  <td>{{  lastModifierUser ? `${lastModifierUser.email} (${lastModifierUser.firstName} ${lastModifierUser.lastName})` : "-" }}</td>
                  <td></td>
                </tr>

<!--                <tr>-->
<!--                  <td>Status</td>-->
<!--                  <td>{{  getProductStatus() }}</td>-->
<!--                  <td></td>-->
<!--                </tr>-->

                <tr>
                  <td>Ilość (jednostki)</td>
                  <td>{{  product.unit }}</td>
                  <td></td>
                </tr>

                <tr>
                  <td>Liczba wariacji</td>
                  <td>{{  product.variationsCount }}</td>
                  <td></td>
                </tr>

                <tr>
                  <td>Koszt dostawy</td>
                  <td>
                    <template v-if="product.deliveryCost === 0 && shippingCosts">
                      <div :set="shippingCostForProduct = getShippingCost(product.id)">
                        <template v-if="shippingCostForProduct">
                          <p v-for="(shippingCostItem, index) in shippingCostForProduct" :key="index">{{ shippingCostItem.name }}: {{ priceHelper.format(shippingCostItem.cost, market.currency) }}</p>
                        </template>
                        <template v-else>
                          -
                        </template>
                      </div>
                      <!-- {% for productShippingMethod in productShippingMethods %}-->
                      <!-- <span>{{ productShippingMethod.carrier.name }} {{ productShippingMethod.dimension }} - {{ productShippingMethod.shippingCostUsual|number_format(2) }} {{ 'PLN'|trans }}</span></br>-->
                      <!-- {% endfor %}-->
                      <!-- {% else %}-->
                    </template>
                    <template v-else>
                      {{ priceHelper.format(product.deliveryCost, market.currency) }}
                    </template>
                  </td>
                  <td>
                    <button class="btn btn-sm btn-success" @click="openEditElementInTableModal(product, 'deliveryCost', 'Koszt dostawy', 'INT', 'Zmiana kosztu dostawy')">
                      <i class="mdi mdi-pencil"></i>
                    </button>
                  </td>
                </tr>

                <tr>
                  <td>Czas dostawy</td>
                  <td v-if="deliveryUnitsMap">
                    <template v-if="product.deliveryTime === 0">
                      <template v-if="warehouse && warehouse.deliveryTime">
                        Domyślny dla hurt.

                        <div :set="deliveryUnit = getDeliveryUnit(warehouse.deliveryUnit)">
                          <template v-if="deliveryUnit">
                            {{ warehouse.deliveryTime }} {{ deliveryUnit.name }}
                          </template>
                        </div>

                      </template>
                      <template v-else>
                        Globalny
                      </template>
                    </template>
                    <template v-else>
                      <div :set="deliveryUnit = getDeliveryUnit(product.deliveryUnit)">
                        <template v-if="deliveryUnit">
                          {{ product.deliveryTime }} {{ deliveryUnit.name }}
                        </template>
                      </div>
                    </template>
                  </td>
                  <td>
                    <button class="btn btn-sm btn-success" @click="openEditElementInTableModal(product, '', 'Czas dostawy', 'DELIVERY_TIME', 'Zmiana czasu dostawy')">
                      <i class="mdi mdi-pencil"></i>
                    </button>
                  </td>
                </tr>

                <tr>
                  <td>Data utworzenia</td>
                  <td>{{ dateUtil.asDateTimeNormal(product.createdAt) }}</td>
                  <td></td>
                </tr>

                <tr>
                  <td>Data ostatniej aktualizacji</td>
                  <td>{{ product.updatedAt ? dateUtil.asDateTimeNormal(product.updatedAt) : "Brak" }}</td>
                  <td></td>
                </tr>

                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

        <div class="col-12 col-lg-6">
          <div class="card">
            <div class="card-body">
              <h3>Notatka</h3>

              <ecat-ckeditor v-model="product.note" />

              <div class="float-right py-3">
                <a class="btn btn-success" @click="saveProduct(null, false)">Zapisz</a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12">
          <div class="card">
            <div class="card-body">

              <div class="button-items">
                <a class="btn btn-danger" @click="openEditElementInTableModal(product, 'allegroException', 'Wyjątek allegro [<60zł]', 'BOOLEAN', 'Zmiana wyjątku Allegro')">Ustaw jako wyjątek Allegro [&lt;60zł]</a>
                <a class="btn btn-danger" @click="openEditElementInTableModal(product, 'allegroBlocked', 'Blokada Allegro', 'BOOLEAN', 'Zmiana blokady Allegro')">Blokada Allegro</a>

                <a v-if="product.status === 'BLOCKED'" class="btn btn-danger" @click="blockOrUnBlockProduct(false)">Odblokuj</a>
                <a v-else class="btn btn-success" @click="blockOrUnBlockProduct(true)">Zablokuj</a>

<!--                <a v-if="!product.checked" class="btn btn-success mx-2" @click="acceptProduct">Zatwierdź</a>-->
              </div>
            </div>
          </div>
        </div>

        <div class="col-6" v-if="productVariations">
          <div class="card">
            <div class="card-body">
              <h3>Wariacje:</h3>

              <div v-for="(variation, index) in productVariations" :key="index">
                <p>{{ $t('products.variations.details') }}</p>
                <b-button v-if="!variation.originalProductVariationId && !depotSectionStocks.some(element => element.productVariationId === variation.id)" @click="$refs.depotDuplicateProductModal.openModal(product, variation.id)" variant="primary">Zduplikuj do magazynu ECAT</b-button>

                <div class="table-responsive py-3">
                  <table class="table table-bordered">
                    <tbody>

                    <tr>
                      <th>Nazwa</th>
                      <td>{{  variation.name }}</td>
<!--                      <td>-->
<!--                        <button class="btn btn-sm btn-success" @click="openEditElementInTableModal(product, 'name', 'Nazwa produktu', 'STRING', 'Zmiana nazwy produktu')">-->
<!--                          <i class="mdi mdi-pencil"></i>-->
<!--                        </button>-->
<!--                      </td>-->
                    </tr>

                    <tr>
                      <th>Identyfikator</th>
                      <td>{{ variation.id }}</td>
                    </tr>

                    <tr v-if="variation.warehouseVariationId">
                      <th>Indeks</th>
                      <td>{{ variation.warehouseVariationId }}</td>
                    </tr>

                    <tr>
                      <th>Kod producenta</th>
                      <td>{{  variation.manufacturerCode ? variation.manufacturerCode : '-' }}</td>
                      <td></td>
                    </tr>

                    <tr>
                      <th>SKU</th>
                      <td>{{ variation.sku ? 'W' + variation.sku : "-" }}</td>
                    </tr>

                    <tr>
                      <th>EAN</th>
                      <td>{{ variation.eanCodeOverride ? variation.eanCodeOverride : variation.ean ? jsonUtil.asArray(variation.ean)[0] : "-" }}</td>
                      <td>
                        <button class="btn btn-sm btn-success" @click="openEditElementInTableModal(variation, 'eanCodeOverride', 'Kod EAN', 'STRING', 'Zmiana kodu EAN', 'PRODUCT_VARIATION')">
                        <i class="mdi mdi-pencil" />
                      </button>
                      </td>
                    </tr>

                    <tr>
                      <th>Cena sprzedaży brutto ECAT</th>
                      <td>{{ priceHelper.format(variation.priceMarginTaxIncluded, market.currency) }}</td>
                    </tr>

                    <tr>
                      <th>Cena zakupu netto</th>
                      <td>{{ priceHelper.format(variation.wholesalePrice, market.currency) }}</td>
                    </tr>

                    <tr>
                      <th>Liczba sztuk</th>
                      <td>
                        <p>Zarezerwowanych sztuk: {{ getProductVariationStockReservation(variation.id) }}</p>
                        <p>Hurtownia: {{ variation.availableIn }}</p>

                        <template v-if="depotSectionStocks">
                          <div v-for="(depotSectionStock, index) in getDepotSectionStockDepots(variation.id)" :key="index">
                            Hurtownia {{ depotSectionStock.depotName }} (suma: {{ getSumDepotSectionStocks(depotSectionStock.depotId, variation.id) }}):
                            <br />
                            <ul v-for="(depotSection, index) in depotSectionStocks.filter(element => element.depotId === depotSectionStock.depotId && element.productVariationId === depotSectionStock.productVariationId)" :key="index">
                              <li class="mb-2">
                                {{ depotSection.depotSectionName }}: {{ depotSection.quantity }}
                                <b-button variant="success" class="btn-sm" @click="openChangeStockModal(depotSectionStock.depotSectionId, variation.id)">Zmień stan magazynowny</b-button>
                              </li>
                            </ul>
                          </div>

                          <p>Łącznie: (<span :id="`available_in_${variation.id}`" class="text-underline">{{ variation.availableIn }}</span>
                            + <span :id="`sum_depot_stocks_${variation.id}`" class="text-underline">{{ getSumDepotStocks(variation.id) }}</span>)
                            - <span :id="`stock_reservation_${variation.id}`" class="text-underline">{{ getProductVariationStockReservation(variation.id) }}</span>
                            = <span :id="`sum_${variation.id}`" class="text-underline">{{ variation.availableIn + getSumDepotStocks(variation.id) - getProductVariationStockReservation(variation.id) }}</span>
                          </p>
                          <b-tooltip :target="`available_in_${variation.id}`" placement="right">Ilość z Hurtowni</b-tooltip>
                          <b-tooltip :target="`sum_depot_stocks_${variation.id}`" placement="right">Ilość z Hurtowni ECAT</b-tooltip>
                          <b-tooltip :target="`stock_reservation_${variation.id}`" placement="right">Zarezerwowanych sztuk</b-tooltip>
                          <b-tooltip :target="`sum_${variation.id}`" placement="right">Suma sztuk</b-tooltip>
                        </template>

                        <div class="py-4 button-items" v-if="depot">
                          <b-button variant="primary" class="btn-sm" @click="openAddStockModal(variation.id)">Przyjmij na magazyn</b-button>
                          <b-button variant="danger" class="btn-sm" @click="openMoveStockModal(variation.id)">Przesuń między magazynami</b-button>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <th>Minimalna liczba sztuk</th>
                      <td>{{ variation.minQuantity }}

                        <button class="btn btn-sm btn-success" @click="openEditElementInTableModal(variation, 'minQuantity', 'Minimalna liczba sztuk', 'INT', 'Zmiana minimalnej liczby sztuk', 'PRODUCT_VARIATION')">
                          <i class="mdi mdi-pencil"></i>
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <th>Atrybuty</th>
                      <td>
                        <div v-if="productAttribute(variation.id)">
                          <p v-for="(attribute, index) in productAttribute(variation.id).attributes" :key="index">
                            <b>{{ attribute.attributeGroupName }}</b>: {{ attribute.attributeName }}
                          </p>
                        </div>
                        <div v-else>
                          Brak
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>Gabaryt</th>
                      <td>
                        <span class="badge font-size-11" :class="productInternalIdentificationByProductVariationId(variation.id) === 'NONE' ? 'badge-soft-danger' : 'badge-soft-success'">
                          {{ oversizeTypeHelper.getOversizeTypeText(productInternalIdentificationByProductVariationId(variation.id), 'Typ ') }}
                        </span>

                        <div class="button-items py-2">
                          <b-button variant="danger" class="btn-sm" @click="openChangeOversizeModal(variation.id)">Zmień status gabarytu</b-button>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <th>Opis</th>
                      <td>
                        <div v-html="variation.description"></div>
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <th>Zdjęcia</th>
                      <td>
                        <template v-if="jsonUtil.asArray(variation.images).length > 0">
                          <div class="row">
                            <div class="col-12 col-lg-2 mb-2" v-for="(image, index) in jsonUtil.asArray(variation.images)" :key="index">
                              <div class="img-fluid">
                                <!-- TODO: usuwanie zdjęć, wrzucanie zdjęć  -->
                                <img :src="image" alt="Product Image" width="96" @click="selectProductImages(jsonUtil.asArray(variation.images), index)" />
                              </div>
                            </div>
                          </div>
                        </template>
                      </td>
                      <td></td>
                    </tr>

                    <tr>
                      <th>Data utworzenia</th>
                      <td>{{ dateUtil.asDateTimeNormal(variation.createdAt) }}</td>
                      <td></td>
                    </tr>

                    <tr>
                      <th>Data ostatniej aktualizacji</th>
                      <td>{{ variation.updatedAt ? dateUtil.asDateTimeNormal(variation.updatedAt) : "Brak" }}</td>
                      <td></td>
                    </tr>

                    </tbody>
                  </table>
                </div>

                <hr />
              </div>
            </div>
          </div>
        </div>

        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <h4>Logi</h4>

              <ecat-table
                  ref="table"
                  :table="logsTable"
                  :fields="getLogsFields()"
                  :items="loadProductLogs"
                  :pagination-top="true"
              >
                <template v-slot:userId="{ item }">
                  {{ item.userFirstName }} {{ item.userLastName }} ({{ item.userEmail }})
                </template>
              </ecat-table>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="text-center py-3">
          <h5>{{ $t('message.loading') }}</h5>
          <span aria-hidden="true" class="m-2 spinner-border text-primary"></span>
        </div>
      </template>
    </div>

    <b-modal
        size="xl"
        id="modal-1"
        v-model="modals.editElementInTable.visibleModal"
        hide-footer
        :title="`${modals.editElementInTable.title}`"
        title-class="font-18"
        @esc="hideEditElementInTableModal"
        @hide="hideEditElementInTableModal">

      <template v-if="modals.editElementInTable.type === 'INT'">
        <label>{{ modals.editElementInTable.elementName }}</label>
        <input v-model.number="modals.editElementInTable.item[modals.editElementInTable.element]" type="number" class="form-control" />
      </template>
      <template v-else-if="modals.editElementInTable.type === 'DOUBLE'">
        <label>{{ modals.editElementInTable.elementName }}</label>
        <input v-model.number="modals.editElementInTable.item[modals.editElementInTable.element]" type="number" class="form-control custom-number-with-gap" step="0.1" />
      </template>
      <template v-else-if="modals.editElementInTable.type === 'STRING'">
        <label>{{ modals.editElementInTable.elementName }}</label>
        <input v-model="modals.editElementInTable.item[modals.editElementInTable.element]" type="text" class="form-control" />
      </template>
      <template v-else-if="modals.editElementInTable.type === 'CKEDITOR'">
        <label>{{ modals.editElementInTable.elementName }}</label>
        <ecat-ckeditor v-model="modals.editElementInTable.item[modals.editElementInTable.element]" />
      </template>
      <template v-else-if="modals.editElementInTable.type === 'BOOLEAN'">
        <div class="form-group">
          <div class="custom-control custom-checkbox mb-3">
            <input v-model="modals.editElementInTable.item[modals.editElementInTable.element]" type="checkbox" class="custom-control-input" :id="`boolean-${modals.editElementInTable.element}`" />
            <label class="custom-control-label" :for="`boolean-${modals.editElementInTable.element}`">{{ modals.editElementInTable.elementName }}</label>
          </div>
        </div>
      </template>
      <template v-else-if="modals.editElementInTable.type === 'TAX'">
        <label>{{ modals.editElementInTable.elementName }}</label>
        <ecat-multiselect @change="value => modals.editElementInTable.item.taxId = value"
                          save-id="id"
                          placeholder="Wybierz stawke VAT"
                          load-url="/tax/list/pagination"
                          query-url="/tax/by-name"
                          param="name"
                          :custom-label="value => value.rate + '%'">
        </ecat-multiselect>
      </template>
      <template v-else-if="modals.editElementInTable.type === 'CATEGORY'">
        <label>{{ modals.editElementInTable.elementName }}</label>
        <ecat-multiselect @change="value => modals.editElementInTable.item.categoryId = value"
                          view-id="name"
                          save-id="id"
                          :can-unselect="true"
                          placeholder="Wszystkie kategorie"
                          load-url="/category/list/pagination"
                          query-url="/category/by-name"
                          param="name">
        </ecat-multiselect>
      </template>
      <template v-else-if="modals.editElementInTable.type === 'DELIVERY_TIME'">
        <div class="form-group">
          <label>Czas trwania dostawy</label>
          <input v-model="modals.editElementInTable.item.deliveryTime" type="number" class="form-control" />
        </div>

        <div class="form-group">
          <label>Jednostka</label>
          <select v-model="modals.editElementInTable.item.deliveryUnit" class="custom-select">
            <option v-for="(unit, index) in deliveryUnits" :key="index" :value="unit.deliveryUnit" >{{ unit.name }}</option>
          </select>
        </div>
      </template>

      <div class="text-center">
        <b-button class="mx-2" variant="success" @click="saveProduct(hideEditElementInTableModal, true)">
          Zapisz
        </b-button>

        <b-button variant="danger" class="my-3" @click="hideEditElementInTableModal">
          {{ $t('message.cancel') }}
        </b-button>
      </div>
    </b-modal>

    <b-modal
        id="modal-2"
        v-model="modals.addStock.visible"
        hide-footer
        title="Wrzuć wariację na magazyn"
        title-class="font-18"
        @esc="hideAddStockModal"
        @hide="hideAddStockModal">
      <div class="form-group">
        <ecat-multiselect
            @change="value => modals.addStock.form.depotSectionId = value"
            :can-unselect="true"
            save-id="id"
            view-id="name"
            label="Magazyn"
            placeholder="Wybierz magazyn"
            load-url="/depot/section/pagination"
            query-url="/depot/section/by-name"
            list-name="depotSections"
            param="name"
            :custom-params="{ depotId: depot?.id || '' }"
            :class="{ 'is-invalid': submitted && $v.modals.addStock.form.depotSectionId.$error }"
        />

        <div v-if="!$v.modals.addStock.form.depotSectionId.required" class="invalid-feedback">{{ $t('message.required')}}</div>
      </div>

      <div class="form-group">
        <label>Liczba sztuk</label>
        <input v-model="modals.addStock.form.stockQuantity" type="number" class="form-control" :class="{ 'is-invalid': submitted && $v.modals.addStock.form.stockQuantity.$error }" />
        <div v-if="!$v.modals.addStock.form.stockQuantity.required" class="invalid-feedback">{{ $t('message.required')}}</div>
      </div>

      <div class="text-center">
        <b-button class="mx-2" variant="success" @click="addStock">
          Zapisz
        </b-button>

        <b-button variant="danger" class="my-3" @click="hideAddStockModal">
          {{ $t('message.cancel') }}
        </b-button>
      </div>
    </b-modal>

    <b-modal
        id="modal-3"
        v-model="modals.moveStock.visible"
        hide-footer
        title="Przesunięcie między magazynami"
        title-class="font-18"
        @esc="hideMoveStockModal"
        @hide="hideMoveStockModal">
      <div class="form-group">

        <ecat-multiselect
            @change="value => modals.moveStock.form.depotSectionIdFrom = value"
            :can-unselect="true"
            save-id="id"
            view-id="name"
            label="Magazyn źródłowy"
            placeholder="Wybierz magazyn"
            load-url="/depot/section/pagination"
            query-url="/depot/section/by-name"
            list-name="depotSections"
            param="name"
            :custom-params="{ depotId: depot?.id || '' }"
            :class="{ 'is-invalid': submitted && $v.modals.moveStock.form.depotSectionIdFrom.$error }"
        />

        <div v-if="!$v.modals.moveStock.form.depotSectionIdFrom.required" class="invalid-feedback">{{ $t('message.required')}}</div>
      </div>

      <div class="form-group">
        <ecat-multiselect
            @change="value => modals.moveStock.form.depotSectionIdTo = value"
            :can-unselect="true"
            save-id="id"
            view-id="name"
            label="Magazyn docelowy"
            placeholder="Wybierz magazyn"
            load-url="/depot/section/pagination"
            query-url="/depot/section/by-name"
            list-name="depotSections"
            param="name"
            :custom-params="{ depotId: depot?.id || '' }"
            :class="{ 'is-invalid': submitted && $v.modals.moveStock.form.depotSectionIdTo.$error }"
        />

        <div v-if="!$v.modals.moveStock.form.depotSectionIdTo.required" class="invalid-feedback">{{ $t('message.required')}}</div>
      </div>

      <div class="form-group">
        <label>Liczba sztuk</label>
        <input v-model="modals.moveStock.form.stockQuantity" type="number" class="form-control" :class="{ 'is-invalid': submitted && $v.modals.moveStock.form.stockQuantity.$error }" />
        <div v-if="!$v.modals.moveStock.form.stockQuantity.required" class="invalid-feedback">{{ $t('message.required')}}</div>
      </div>

      <div class="text-center">
        <b-button class="mx-2" variant="success" @click="moveStock">
          Zapisz
        </b-button>

        <b-button variant="danger" class="my-3" @click="hideMoveStockModal">
          {{ $t('message.cancel') }}
        </b-button>
      </div>
    </b-modal>

    <b-modal
        id="modal-3"
        v-model="modals.changeStock.visible"
        hide-footer
        title="Zmień stan magazynowy"
        title-class="font-18"
        @esc="hideChangeStockModal"
        @hide="hideChangeStockModal">
      <div class="form-group">
        <label>Liczba sztuk</label>
        <input v-model="modals.changeStock.form.stockQuantity" type="number" class="form-control" :class="{ 'is-invalid': submitted && $v.modals.changeStock.form.stockQuantity.$error }" />
        <div v-if="!$v.modals.changeStock.form.stockQuantity.required" class="invalid-feedback">{{ $t('message.required')}}</div>
      </div>

      <div class="text-center">
        <b-button class="mx-2" variant="success" @click="changeStock">
          Zapisz
        </b-button>

        <b-button variant="danger" class="my-3" @click="hideChangeStockModal">
          {{ $t('message.cancel') }}
        </b-button>
      </div>
    </b-modal>

    <b-modal
        id="modal-4"
        v-model="modals.changeOversize.visible"
        title="Zmiana statusu gabarytu"
        title-class="font-18"
        hide-footer
        @hide="hideChangeOversizeModal"
        @esc="hideChangeOversizeModal">
      <form>
        <div class="form-group">
          <h4 class="text-center">Typ gabarytu</h4>

          <div class="row d-flex justify-content-center">
            <div class="col-12 col-lg-10">
              <button v-for="(item, index) in packageTypes" :key="index" class="package-type" :class="{ 'active-package': modals.changeOversize.form.oversizeType === item, 'font-size-24': item === 'NONE' }" type="button" @click="modals.changeOversize.form.oversizeType = item">{{ oversizeTypeHelper.getOversizeTypeText(item) }}</button>
            </div>
          </div>

          <input hidden :class="{ 'is-invalid': $v.modals.changeOversize.form.oversizeType.$error }" />
          <div v-if="!$v.modals.changeOversize.form.oversizeType.required" class="invalid-feedback">{{ $t('message.required') }}</div>
        </div>

        <div class="text-center">
          <b-button @click="setDimensions" variant="success">Zapisz</b-button>
          <b-button class="ml-1" variant="danger" @click="hideChangeOversizeModal">{{ $t('message.cancel') }}</b-button>
        </div>
      </form>
    </b-modal>

    <depot-duplicate-product-modal ref="depotDuplicateProductModal"/>

  </Layout>
</template>